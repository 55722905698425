import dayjs from "dayjs"
const auth = ({
  to,
  next,
}) => {
  const company = to?.params?.company
  const tokenExp = localStorage.getItem('tokenExp')
  if (tokenExp && dayjs(tokenExp).isBefore(dayjs())) {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExp')
    localStorage.removeItem('supplier_company')
    const loginPath = company ? { name: 'company-login', params: { company } } : { name: 'login' }
    return next(loginPath)
  }
  const isAuth = localStorage.getItem('token')
  const isGod = sessionStorage.getItem('currentCompanySlug') === 'vsimple-integrations'
  if (isAuth && isGod) {
    if (company === 'vsimple-integrations') {
      return next()
    }
    return next({
      name: 'god-user',
      params: {
        company: 'vsimple-integrations',
      },
    })
  }

  if (!isAuth) {
    sessionStorage.setItem('userRequestUrl', JSON.stringify({ name: to.name, query: to.query, params: to.params }))
    const loginPath = company ? { name: 'company-login', params: { company } } : { name: 'login' }
    return next(loginPath)
  }

  return next()
}

export default auth
