import { auth } from '@/api'
import router from '@/router'
import { removeChatWootScript } from '@/utils/snippets'
import getFieldFromToken from '@/utils/getFieldFromToken'
import dayjs from "dayjs"

export default {
  login(ctx, { payload, company }) {
    return auth.login(payload)
      .then(({ data }) => {
        return ctx.dispatch('setToken', {
          token: data.data.token,
          expire: data.data.exp,
          company,
        })
      })
      .catch((err) => {
        throw err
      })
  },
  setToken(ctx, {
    token,
    expire,
    company,
  }) {
    localStorage.setItem('token', token)
    localStorage.setItem('tokenExp', dayjs().add(expire, 's').format())
    const supplierFromToken = getFieldFromToken('sup')
    if (supplierFromToken) {
      localStorage.setItem('supplier_company', supplierFromToken)
      return router.push({
        name: 'pre-orders',
        params: { company: supplierFromToken },
      })
    }

    const uid = getFieldFromToken('uid')

    return ctx.dispatch('companies/getUserCompanies', uid, { root: true }).then((companies) => {
      const userRequestUrl = sessionStorage.getItem('userRequestUrl')
      if (userRequestUrl) {
        sessionStorage.removeItem('userRequestUrl')
        const userRequest = JSON.parse(userRequestUrl)
        const requestCompany = userRequest.params.company
        if (companies.some(({ slug }) => slug === requestCompany)) {
          return router.push(userRequest)
        }
      }

      if (company && companies.some(({ slug }) => slug === company)) {
        return router.push({
          name: 'pre-orders',
          params: { company: company },
        })
      }
      if (!companies.length || companies.length > 1) {
        return router.push({ name: 'home' })
      }

      if (companies.length > 1) {
        return router.push({ name: 'home' })
      }

      if (companies[0].slug === 'vsimple-integrations') {
        return router.push({
          name: 'god-create-supplier',
          params: { company: companies[0].slug },
        })
      }

      return router.push({
        name: 'pre-orders',
        params: { company: companies[0].slug },
      })
    })
  },
  refreshToken({ commit }) {
    return auth.refreshToken().then(({ data }) => {
      commit('layout/setRefreshTokenModalClosed', false, { root: true })
      localStorage.setItem('token', data.data.token)
      localStorage.setItem('tokenExp', dayjs().add(data.data.exp, 's').format())
      const supplierFromToken = getFieldFromToken('sup')
      localStorage.setItem('supplier_company', supplierFromToken)
      return data
    })
  },
  forgotPassword(context, formData) {
    return auth.forgotPassword(formData)
  },
  resetPassword(context, formData) {
    return auth.resetPassword(formData)
  },
  createAccount(context, formData) {
    return auth.createAccount(formData)
      .then(() => {
        router.push({ name: 'login' })
      })
      .catch((err) => {
        throw err
      })
  },
  getAccountData(context, payload) {
    return auth.getAccountData(payload)
  },
  setCompany(context, companySlug) {
    return auth.setCompany(companySlug).then(() => {
      localStorage.setItem('token', token)
      localStorage.setItem('tokenExp', dayjs().add(expire, 's').format())
      router.push({
        name: 'pre-orders',
        params: { company: companySlug },
      })
    })
  },
  async logout({ dispatch }, userId = null) {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExp')
    localStorage.removeItem('supplier_company')
    localStorage.removeItem('withEndCustomers')
    sessionStorage.removeItem('currentCompanySlug')
    if (userId) {
      localStorage.setItem(`${userId}-lastVisitedPage`, router.history.current.path)
    }
    dispatch('resetAll', {}, { root: true })
    // removeSmartLookScript()
    removeChatWootScript()
    router.push({
      name: 'login',
      query: { force: 1 },
    })
  },
}
