import { createRouter, createWebHistory } from "vue-router"

import nextFactory from '@/utils/nextFactory'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_UR),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.params.company) {
    sessionStorage.setItem('currentCompanySlug', to.params.company)
  }
  if (import.meta.env.VITE_APP_ENV !== 'production') {
    window.document.title = `Vsimple (${import.meta.env.VITE_APP_ENV})`
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]
    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
