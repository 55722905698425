import axios from "axios"
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'
import catchUnauthorizedError from '@/utils/catchUnauthorizedError'
import catchForbiddenError from '@/utils/catchForbiddenError'
import catch404 from '@/utils/catch404'
import eventBus from "@/eventBus"
import router from '@/router'
import LogRocket from 'logrocket'
import { apiErrorHandler } from "@/utils/apiErrorHandler"
import mergeWith from 'lodash/mergeWith'
import { ERROR_MESSAGES } from "@/constants/ERROR_MESSAGES"

function simpleNetworkErrorCheck(error) {
  if (!error?.code) {
    return true
  } else {
    return isNetworkOrIdempotentRequestError(error)
  }
}

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL + '/v1',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
})


axiosRetry(httpClient, {
  retries: 1,
  retryCondition: simpleNetworkErrorCheck,
  retryDelay: () => 2000,
})

let recordingURL = null

httpClient.interceptors.request.use((config) => {
  if (['production', 'staging'].includes(import.meta.env.VITE_APP_ENV) && !recordingURL) {
    LogRocket.getSessionURL(function(sessionURL) {
      recordingURL = sessionURL
    })
  }

  const token = localStorage.getItem('token')

  if (config.url.includes('{currentCompanySlug}')) {
    const companySlug = router.currentRoute.value.params.company || sessionStorage.getItem('currentCompanySlug')

    if (!companySlug) {
      throw new Error(ERROR_MESSAGES.missedCurrentCompany)
    }

    config.url = config.url.replace('{currentCompanySlug}', companySlug)
  }

  if (token && !config.ignoreGlobalAuthTokenSet) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (recordingURL) {
    config.headers['X-LogRocket-URL'] = recordingURL
  }

  return config
}, (err) => Promise.reject(err))

httpClient.interceptors.response.use((response) => response, (error) => {
  if (!axios.isAxiosError(error) || error.config?.skipResponseInterceptor) {
    return Promise.reject(error)
  }

  if (axios.isCancel(error)) {
    LogRocket.log('Cancelled by axios', error)
    return Promise.reject(error)
  }

  if (error.code === 'ECONNABORTED') {
    LogRocket.log('Cancelled by browser', error)
    return Promise.reject(error)
  }

  if (error.response?.data?.errors?.relatedFeatures?.length) {
    return Promise.reject(error)
  }

  if (error?.code) {
    LogRocket.log(`Error appeared: ${error.code}`, error)
  }

  if (error?.response?.status === 401 && !error?.config?.skipDefaultUnauthorizedBehaviour) {
    catchUnauthorizedError()
    return Promise.reject(error)
  }
  if (error?.response?.status === 403 && !error?.config?.skipDefaultForbiddenBehaviour) {
    if (error?.response?.data?.errors?.automationValidation) {
      const { automationValidation: automationValidations } = error.response.data.errors

      const customFieldsErrorsMap = new Map()

      automationValidations.forEach((automationValidation) => {
        automationValidation.customFields.forEach((customFieldSlug) => {
          const storedValue = customFieldsErrorsMap.get(customFieldSlug)
          if (storedValue) {
            return customFieldsErrorsMap.set(
              customFieldSlug,
              [storedValue, automationValidation.message].join('<br>'),
            )
          }

          customFieldsErrorsMap.set(
            customFieldSlug,
            automationValidation.message,
          )
        })

        Object.entries(automationValidation.childObjects).forEach(([customFieldSlug, value]) => {
          const storedValue = customFieldsErrorsMap.get(customFieldSlug)

          const formattedValueWithMessage = Object.entries(value).reduce((acc, [key, fieldValue]) => {
            return {
              ...acc,
              [key]: Object.fromEntries(fieldValue.map((val) => [val, automationValidation.message])),
            }
          }, {})

          if (storedValue) {
            return customFieldsErrorsMap.set(
              customFieldSlug,
              mergeWith(storedValue, formattedValueWithMessage, (objValue, srcValue) => {
                if (typeof objValue === 'string') {
                  return [objValue, srcValue].join('<br>')
                }
              }),
            )
          }

          customFieldsErrorsMap.set(
            customFieldSlug,
            formattedValueWithMessage,
          )
        })
      })

      if (!error?.config?.ignoreEventBus) {
        eventBus.$emit('statusErrorCaptured', {
          automationValidations,
          customFieldsErrorsMap,
          hideValidationSections: Boolean(error?.config?.hideValidationSections),
          recordId: error?.config?.validationRecordId || null,
        })
      }

      return Promise.reject({
        automationValidations,
        customFieldsErrorsMap,
        hideValidationSections: Boolean(error?.config?.hideValidationSections),
        recordId: error?.config?.validationRecordId || null,
      })
    }

    catchForbiddenError()
    return Promise.reject(error)
  }
  if (error?.response?.status === 404 && !error.config.ignoreGlobalNotFoundCatch) {
    catch404()
    return Promise.reject(error)
  }

  if (error?.response?.status === 404 && error.config.ignoreGlobalNotFoundCatch) {
    return Promise.reject(error)
  }

  if (!error?.response?.status || String(error?.response?.status || '')[0] === '5') {
    if (error?.config['axios-retry'].retryCount >= 1) {
      eventBus.$emit('requestErrorCaptured', 504)
      return Promise.reject(error)
    }
  }

  apiErrorHandler(error)

  return Promise.reject(error)
})

export default httpClient
